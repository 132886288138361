// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import TaskAllocator from "../../blocks/TaskAllocator/src/TaskAllocator";
import CfIntegrationWithBioMetric from "../../blocks/CfIntegrationWithBioMetric/src/CfIntegrationWithBioMetric";
import DataImportexportcsv from "../../blocks/DataImportexportcsv/src/DataImportexportcsv";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import FacialTracking from "../../blocks/FacialTracking/src/FacialTracking";
import MultilevelApproval from "../../blocks/MultilevelApproval/src/MultilevelApproval";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import ProjectTaskTracking from "../../blocks/ProjectTaskTracking/src/ProjectTaskTracking";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import TimeTrackingBilling from "../../blocks/TimeTrackingBilling/src/TimeTrackingBilling";
import CfFacialRecognition6 from "../../blocks/CfFacialRecognition6/src/CfFacialRecognition6";
import LanguageSupport from "../../blocks/LanguageSupport/src/LanguageSupport";
import FileAttachment from "../../blocks/FileAttachment/src/FileAttachment";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import TaskList from "../../blocks/TaskList/src/TaskList";
import TimesheetManagement from "../../blocks/TimesheetManagement/src/TimesheetManagement";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";



const routeMap = {
TaskAllocator:{
 component:TaskAllocator,
path:"/TaskAllocator"},
CfIntegrationWithBioMetric:{
 component:CfIntegrationWithBioMetric,
path:"/CfIntegrationWithBioMetric"},
DataImportexportcsv:{
 component:DataImportexportcsv,
path:"/DataImportexportcsv"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
FacialTracking:{
 component:FacialTracking,
path:"/FacialTracking"},
MultilevelApproval:{
 component:MultilevelApproval,
path:"/MultilevelApproval"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
ProjectTaskTracking:{
 component:ProjectTaskTracking,
path:"/ProjectTaskTracking"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
TimeTrackingBilling:{
 component:TimeTrackingBilling,
path:"/TimeTrackingBilling"},
CfFacialRecognition6:{
 component:CfFacialRecognition6,
path:"/CfFacialRecognition6"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
FileAttachment:{
 component:FileAttachment,
path:"/FileAttachment"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
TimesheetManagement:{
 component:TimesheetManagement,
path:"/TimesheetManagement"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;